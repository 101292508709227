<template>
    <p>
        {{ config.label }}
        <v-divider v-if="!!config.divider"></v-divider>
    </p>
</template>

<script>

export default {
    name: 'FormParagraph',
    props: ['config'],
}
</script>
